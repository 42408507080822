import { useIntl } from "gatsby-plugin-intl";
import React from "react";
import Container from "../components/Container";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import BlogList from "../components/BlogList";
import { css } from "@emotion/core";

const BlogPage = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "blog.pageTitle" })}
        description={intl.formatMessage({ id: "blog.description" })}
      />
      <Container
        css={css`
          margin-top: 20px;
        `}
      >
        <BlogList all />
      </Container>
    </Layout>
  );
};

export default BlogPage;
